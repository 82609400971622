import { defineStore } from "pinia";
import {
  type ImportsResponse,
  RequestsImportApi,
  type UploadRequestsCSVRequest,
  RequestsApi,
  AIAgentsApi,
} from "@simbelapp/order-sdk";
import type {
  IGetSelectedRequests,
  IRequestStore,
  IUpdateRequestPayload,
} from "~/utils/interfaces/requests-interfaces";
import { useRequestsApi } from "~/composables/requests/useRequestsApi";
import { useFeedback } from "~/composables/feedback/useFeedback";
import { useEmployeeTrainingsStore } from "~~/store/employee-trainings/employee-trainings.store";
import { RequestStatus } from "~/utils/enums/request.enums";
import { usePagination } from "~/composables/paginations/usePagination";
import { useSDKApi, useSDKApiOlder } from "~/composables/api/useSDKApi";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import { AIAgentRolesEnum } from "~/utils/enums/ai-agent.enums";
export const useRequestsStore = defineStore("requestsStore", {
  state: (): IRequestStore => {
    return {
      requests: null,
      request_details: null,
      show_refuse_modal: false,
      show_cancel_modal: false,
      show_remove_modal: false,
      show_csv_import_modal: false,
      show_duplicated_modal: false,
      showAIAgentModal: false,
      AIAgentContextId: null,
      AIAgentMessages: [],
      AIAgentUserQuery: null,
      selected_requests_list: [],
      excluded_requests_list: [],
      duplicated_requests: [],
    };
  },
  getters: {},
  actions: {
    async fetchRequests(): Promise<void> {
      const requestsApi = useRequestsApi();
      const feedback = useFeedback();

      const resp = await requestsApi.fetchRequests();

      if (resp) {
        this.requests = resp;
      } else {
        feedback.error(`erreur interne`, "small");
      }
    },

    async historyAIAgent(): Promise<void> {
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, AIAgentsApi);
      try {
        if (this.request_details?.user_id && this.AIAgentUserQuery) {
          this.AIAgentMessages.push({
            message: this.AIAgentUserQuery,
            role: AIAgentRolesEnum.USER,
          });

          const result = await apiInstance.userHistoryAgent({
            aIAgentHistoryDTO: {
              userId: this.request_details.user_id.user_id,
              userQuery: this.AIAgentUserQuery,
              requestId: this.request_details.request_id,
              ...(this.AIAgentContextId && { contextId: this.AIAgentContextId }),
            },
          });

          this.AIAgentUserQuery = null;

          if (result?.agentResponse && result?.contextId) {
            this.AIAgentContextId = result.contextId;
            this.AIAgentMessages.push({
              message: result.agentResponse ?? "",
              role: AIAgentRolesEnum.SYSTEM,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async validateIfRequestCanBeProcessed(): Promise<void> {
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, AIAgentsApi);
      try {
        if (this.request_details?.user_id && this.AIAgentUserQuery) {
          this.AIAgentMessages.push({
            message: this.AIAgentUserQuery,
            role: AIAgentRolesEnum.USER,
          });

          const result = await apiInstance.requestValidatorAgent({
            aIAgentRequestValidatorDTO: {
              userId: this.request_details.user_id.user_id,
              requestId: this.request_details.request_id,
              ...(this.AIAgentContextId && { contextId: this.AIAgentContextId }),
            },
          });

          this.AIAgentUserQuery = null;

          if (result?.agentResponse && result?.contextId) {
            this.AIAgentContextId = result.contextId;
            this.AIAgentMessages.push({
              message: result.agentResponse ?? "",
              role: AIAgentRolesEnum.SYSTEM,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async fetchRequestDetails(request_id: string): Promise<void> {
      const requestsApi = useRequestsApi();
      const { data } = await requestsApi.fetchRequestDetails(request_id);
      this.request_details = data.value;
      if (this.request_details) {
        this.request_details.notifications = {
          refused_employee_request: false,
          refused_manager_request: false,
        };
      }
    },

    async getSelectedUsers(): Promise<IGetSelectedRequests> {
      const requestStore = useRequestsStore();
      const requestsApi = useRequestsApi();

      const { data } = await requestsApi.selectedRequestUsers(
        requestStore.selected_requests_list?.map((r) => r.request_id) ?? [],
        requestStore.excluded_requests_list?.map((r) => r.request_id) ?? [],
      );
      return data.value as IGetSelectedRequests;
    },

    async addManagerInput(request_id: string, managerInput: { priority: string; manager_input: string }): Promise<any> {
      const requestsApi = useRequestsApi();
      const feedback = useFeedback();

      const { data } = await requestsApi.addManagerInput(request_id, managerInput);

      if (data.value && this.request_details) {
        this.request_details.status = data.value.new_state;
        feedback.success("Vos modifications ont bien été sauvegardées", "small");
      } else {
        feedback.error("erreur", "small");
      }

      return !!data.value;
    },

    async refuseRequest(
      comment: string,
      refused_employee_request: boolean,
      refused_manager_request: boolean,
    ): Promise<any> {
      const feedback = useFeedback();
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, RequestsApi);
      try {
        if (this.request_details?.request_id && this.request_details?.not_processed) {
          await apiInstance.refuseRequests({
            refuseRequestsDto: {
              requestIds: [this.request_details.request_id],
              externalHrComment: comment,
              refusedEmployeeRequest: refused_employee_request,
              refusedManagerRequest: refused_manager_request,
            },
          });
          this.fetchRequestDetails(this.request_details.request_id);
          feedback.success("Vos modifications ont bien été sauvegardées", "small");
        }
      } catch (error) {
        feedback.error(`Une erreur est survenue`, "small");
      }
    },

    async batchPatchRequestsStatus(
      requestIds: string[],
      comment: string,
      status: RequestStatus,
      refused_employee_request?: boolean,
      refused_manager_request?: boolean,
    ): Promise<void> {
      if (requestIds?.length) {
        const requestsApi = useRequestsApi();
        const feedback = useFeedback();
        switch (status) {
          case RequestStatus.REQUEST_CANCELED: {
            const cancelDatas = await requestsApi.cancelRequest(requestIds, comment);
            if (cancelDatas?.data?.value) {
              feedback.success("Vos modifications ont bien été sauvegardées", "small");
            }
            break;
          }
          case RequestStatus.REQUEST_REFUSED: {
            if (refused_employee_request !== undefined && refused_manager_request !== undefined) {
              const refuseDatas = await requestsApi.refuseRequest(
                requestIds,
                comment,
                refused_employee_request,
                refused_manager_request,
              );
              if (refuseDatas?.data?.value) {
                feedback.success("Vos modifications ont bien été sauvegardées", "small");
              }
            }
            break;
          }
          default:
            break;
        }
        const paginate = usePagination();

        await paginate.fetchData();
      }
    },

    async cancelRequest(): Promise<any> {
      if (this.request_details?.request_id && this.request_details?.not_processed) {
        const requestsApi = useRequestsApi();
        const feedback = useFeedback();
        const { data } = await requestsApi.cancelRequest([this.request_details.request_id]);
        if (data.value) {
          this.request_details.status = data.value.new_state;
          this.request_details.not_processed = false;
          feedback.success("Vos modifications ont bien été sauvegardées", "small");
        }
      }
    },

    async batchRemoveRequest(requestIds: string[]): Promise<void> {
      if (requestIds?.length) {
        const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, RequestsApi);
        const feedback = useFeedback();
        const data = await apiInstance.permanentDeleteRequests({
          permanentDeleteRequestsRequest: {
            requestIds,
          },
        });
        if (data) {
          feedback.success("Vos modifications ont bien été sauvegardées", "small");
        }
        const paginate = usePagination();

        await paginate.fetchData();
      }
    },

    async cancelEmployeeRequest(): Promise<any> {
      if (this.request_details?.request_id && this.request_details?.not_processed) {
        const requestsApi = useRequestsApi();
        const feedback = useFeedback();
        const { data } = await requestsApi.cancelEmployeeRequest(this.request_details.request_id);

        if (data.value) {
          this.request_details.status = data.value.new_state;
          this.request_details.not_processed = false;
          feedback.success("Votre demande a été annulée", "small");
        }
      }
    },

    async updateRequest(updateRequestPayload: IUpdateRequestPayload): Promise<any> {
      if (this.request_details?.request_id) {
        const requestsApi = useRequestsApi();
        const employeeTrainingsStore = useEmployeeTrainingsStore();

        const feedback = useFeedback();
        const { data } = await requestsApi.updateRequest(this.request_details?.request_id, updateRequestPayload);

        if (data.value) {
          await this.fetchRequestDetails(this.request_details?.request_id);
          await employeeTrainingsStore.fetchEmployeeTraining(this.request_details?.request_id);
          await employeeTrainingsStore.fetchAllEmployeeTrainings();
          this.request_details.not_processed = false;
          feedback.success("Vos modifications ont bien été sauvegardées", "small");
        }
      }
    },

    async importRequests(uploadUsersCSV: UploadRequestsCSVRequest): Promise<ImportsResponse | null> {
      const feedback = useFeedback();
      const apiInstance = await useSDKApiOlder(MicroserviceEnum.ORDERS, RequestsImportApi);
      const response: ImportsResponse | null = await apiInstance
        .uploadRequestsCSV(uploadUsersCSV)
        .catch(async (error) => {
          const errorResponse = await error.response.json();
          if (errorResponse.message.includes("Parallel import in progress")) {
            feedback.error("Un import est déjà en cours, veuillez attendre avant de relancer", "small");
            return null;
          } else {
            feedback.error("Erreur Interne", "small");
            return null;
          }
        });
      if (response) {
        return response;
      } else {
        return null;
      }
    },
  },
});
